/** @jsx jsx */
import ArrowInCircleButton from 'components/ArrowInCircleButton'
import {graphql} from 'gatsby'
import React, {useState, useMemo} from 'react'
import {jsx} from 'theme-ui'
import {HomeProjectsFragment} from '../graphqlTypes'
import ProjectCard from './ProjectCard'
import Button from './Button'
import matchMediaOr from 'lib/matchMediaOr'

type Props = {
  data: HomeProjectsFragment
}

const HomeProjects: React.FC<Props> = ({data}) => {
  const visibleProjectsLength = useMemo(() => {
    if (matchMediaOr('(min-width: 980px) and (max-width: 1200px)', false)) {
      return 2
    }
    return 3
  }, [])
  const projects = data.allMdx.nodes
  const [currentProjectsIndex, setCurrentProjectsIndex] = useState(0)
  const nextProject = () => {
    if (currentProjectsIndex == projects.length - 1) {
      setCurrentProjectsIndex(0)
    } else {
      setCurrentProjectsIndex(currentProjectsIndex + 1)
    }
  }
  const previousProject = () => {
    if (currentProjectsIndex === 0) {
      setCurrentProjectsIndex(projects.length - 1)
    } else {
      setCurrentProjectsIndex(currentProjectsIndex - 1)
    }
  }
  const visibleProjects = projects.filter((_, index) => {
    return (
      currentProjectsIndex <= index &&
      index < currentProjectsIndex + visibleProjectsLength
    )
  })
  const missingProjectsNumber = visibleProjectsLength - visibleProjects.length
  // Let's complete the projects number
  for (let index = 0; index < missingProjectsNumber; index++) {
    visibleProjects.push(projects[index])
  }
  return (
    <section
      id="home__projects"
      sx={{
        mt: [40, , 121],
        width: ['100%', , 1180],
        maxWidth: '90%',
        mx: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: ['column', , 'row'],
      }}
    >
      <ArrowInCircleButton
        reverse
        onClick={previousProject}
        sx={{
          display: ['none', , 'block'],
        }}
      />
      {visibleProjects.map(({frontmatter, parent}) => {
        let slug = '/cause/' + parent?.name
        if (slug.endsWith('.fr')) {
          slug = 'fr/' + slug.replace('.fr', '')
        }
        const {shortDescription, shortTitle, variableName} = frontmatter
        // @ts-ignore
        const imgObj = data[variableName]
        return (
          <ProjectCard
            key={slug}
            title={shortTitle}
            description={shortDescription}
            slug={slug}
            imgObj={imgObj}
          />
        )
      })}
      <ArrowInCircleButton
        onClick={nextProject}
        sx={{
          display: ['none', , 'block'],
        }}
      />

      <Button
        to="/causes/"
        sx={{
          mt: 45,
          display: [, , 'none'],
        }}
      >
        More Causes
      </Button>
    </section>
  )
}

export default React.memo(HomeProjects)

export const query = graphql`
  fragment HomeProjects on Query {
    allMdx(
      filter: {frontmatter: {type: {eq: "cause"}, language: {eq: $langKey}}}
      sort: {order: ASC, fields: frontmatter___homeOrder}
    ) {
      nodes {
        frontmatter {
          shortDescription
          shortTitle
          variableName
        }
        parent {
          ... on File {
            id
            name
          }
        }
      }
    }
    atlasRelief: file(relativePath: {eq: "project-atlas-relief-small.png"}) {
      childImageSharp {
        fluid(maxWidth: 316) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ansar: file(relativePath: {eq: "project-ansar-small.png"}) {
      childImageSharp {
        fluid(maxWidth: 316) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zakat: file(relativePath: {eq: "project-zakat-small.png"}) {
      childImageSharp {
        fluid(maxWidth: 316) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ramadan: file(relativePath: {eq: "project-ramadan-small.png"}) {
      childImageSharp {
        fluid(maxWidth: 316) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    water4All: file(relativePath: {eq: "project-w4all-small.png"}) {
      childImageSharp {
        fluid(maxWidth: 316) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    banyUmmaty: file(relativePath: {eq: "project-bany-small.png"}) {
      childImageSharp {
        fluid(maxWidth: 316) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    feedingHope: file(relativePath: {eq: "project-feeding-hope-small.png"}) {
      childImageSharp {
        fluid(maxWidth: 316) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
